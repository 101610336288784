import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  text-align: center;

  .closeRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    button {
      .closeIcon {
        color: ${({ theme }) => theme.button_high};
        font-size: 1.75rem;
      }
    }
  }

  .titleRow {
    font-size: 1rem;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.font_secondary};
  }

  .iconRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .iconImage {
      width: 126px;
      height: 125px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 62px;

      p {
        color: ${({ theme }) => theme.background_secondary};
        font-size: 100px;
        margin-top: 19px;
      }
    }

    .iconText {
      font-weight: 700;
      font-size: 1.125rem;
      color: ${({ theme }) => theme.font_secondary};
    }
  }

  .textRow {
    font-weight: 500;
    font-size: 1rem;
    color: ${({ theme }) => theme.font_primary};
    text-align: center;

    p {
      margin-top: 1rem;
      margin-bottom: 1rem;

      &:first-child {
        margin-top: 0;
      }
    }

    @media screen and (min-width: 768px) {
      align-self: center;
    }
  }

  .actionArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;

    div {
      padding: 0 2.75rem;

      button {
        border-radius: 0.5rem;
      }
    }
  }
`;
