import { MdOutlineClose, MdOutlineStorefront } from 'react-icons/md';

import { Button } from '@components/Button';

import { getUserAgent } from '@util/getUserAgent';

import { Container } from './styles';

interface IProps {
  hide: () => void;
  userIsManager: boolean;
}

const somaBoxReleaseDay = new Date('11/11/2024');

const today = new Date();

const WebActions = ({ userIsManager, hide }: IProps) => (
  <>
    {!userIsManager && (
      <>
        <Button
          href="https://apps.apple.com/br/app/somabox/id1636403657"
          target="_blank"
        >
          <p className="modalButton">BAIXAR O SOMABOX - IOS</p>
        </Button>
        <Button
          href="https://play.google.com/store/apps/details?id=app.somalabs.somabox&pcampaignid=web_share"
          target="_blank"
        >
          <p className="modalButton">BAIXAR O SOMABOX - ANDROID</p>
        </Button>
      </>
    )}
    {userIsManager && (
      <Button href="https://somabox.somalabs.com.br/">
        <p className="modalButton">IR PARA O SOMABOX - URL</p>
      </Button>
    )}
    <Button
      variant="outlined"
      onClick={() => {
        if (typeof hide === 'function') hide?.();
      }}
    >
      <p className="modalButton">FAZER ISSO MAIS TARDE</p>
    </Button>
  </>
);

const MobileActions = ({
  userIsManager,
  hide,
  currentOs,
}: IProps & {
  currentOs: 'ANDROID' | 'IOS';
}) => {
  const storeUrl = {
    ANDROID:
      'https://play.google.com/store/apps/details?id=app.somalabs.somabox&pcampaignid=web_share',
    IOS: 'https://apps.apple.com/br/app/somabox/id1636403657',
  };

  return (
    <>
      {!userIsManager && (
        <Button href={storeUrl[currentOs]} target="_blank">
          <p className="modalButton">BAIXAR O SOMABOX - IOS</p>
        </Button>
      )}
      {userIsManager && (
        <Button href="https://somabox.somalabs.com.br/">
          <p className="modalButton">IR PARA O SOMABOX - URL</p>
        </Button>
      )}
      <Button
        variant="outlined"
        onClick={() => {
          if (typeof hide === 'function') hide?.();
        }}
      >
        <p className="modalButton">FAZER ISSO MAIS TARDE</p>
      </Button>
    </>
  );
};

const SomaBoxAlert: React.FC<IProps> = ({ hide, userIsManager = false }) => {
  const currentOs = getUserAgent();

  const isAfterSbRelease = today.getTime() < somaBoxReleaseDay.getTime();

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            if (typeof hide === 'function') hide?.();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>
      <div className="iconRow">
        <div className="iconImage">
          <p>
            <MdOutlineStorefront />
          </p>
        </div>
      </div>
      <div className="titleRow">
        <p>
          OBRIGADO PELO SEU TEMPO <br />
          COM O SOMASTORE!
        </p>
      </div>
      <div className="textRow">
        {isAfterSbRelease && (
          <>
            <p>
              O acesso ao SomaStore para{' '}
              <strong>
                {userIsManager ? 'gerente' : 'vendedores'} será removido em
                breve
              </strong>
              .
            </p>
            <p>Obrigado por todo o tempo que passamos juntos :)</p>
            <p>
              Agora vocês possuem o {userIsManager ? '' : 'aplicativo'}{' '}
              <strong>SomaBox</strong> para continuar realizando seus
              atendimentos!
            </p>
          </>
        )}

        {!isAfterSbRelease && (
          <>
            <p>
              Agora vocês possuem o <strong>SomaBox</strong>, para continuar
              realizando seus atendimentos!
            </p>
            <p>
              Abaixo os links do <strong>SomaBox</strong> para você acessar.
            </p>
          </>
        )}
      </div>
      <div className="actionArea">
        {currentOs === 'NAO_IDENTIFICADO' && (
          <WebActions hide={() => hide?.()} userIsManager={userIsManager} />
        )}
        {currentOs !== 'NAO_IDENTIFICADO' && (
          <MobileActions
            hide={() => hide?.()}
            userIsManager={userIsManager}
            currentOs={currentOs}
          />
        )}
      </div>
    </Container>
  );
};

export { SomaBoxAlert };
