import { toast } from 'react-toastify';
import React, { createContext, useContext, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Service import
import { api, getResponseError } from '@services/api';

// Util import
import { sleep } from '@util/sleep';
import { initialShippingData } from '@util/initialShippingData';
import {
  onlyDeliveryOptionEnabled,
  onlyInHandsOptionEnabled,
} from '@util/usersInformations';
import { giftCardReferences } from '@util/giftCardReferences';

// Model import
import {
  IAddress,
  ICustomer,
  IItem,
  IItemShipping,
  IOrderCreationContextData,
  IOrderTotals,
  IPayment,
  ISaleCode,
  IShippingTypes,
  ISimulation,
  ICoupon,
} from './models';

// Feature identification
const featureKey = '@create_order/CONTEXT';

// Context
export const OrderCreationContext = createContext<IOrderCreationContextData>(
  {} as IOrderCreationContextData,
);

// Provider
export const OrderCreationProvider: React.FC = ({ children }) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const history = useHistory();

  // Global states
  const user = useSelector(state => state.auth);

  // Loading states
  const [creatingOrder, setCreatingOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Local states
  const [addingProduct, setAddingProduct] = useState(false);
  const [editingProduct, setEditingProduct] = useState(false);

  const [saleCode, setSaleCode] = useState<ISaleCode>(
    user.sale_codes?.length === 1
      ? {
          code: user.sale_codes?.[0]?.code || '',
          name: user.sale_codes?.[0]?.code
            ? user.sale_codes?.[0]?.name || 'Soma Store'
            : '',
        }
      : {
          code: '',
          name: '',
        },
  );
  const [address, setAddress] = useState<IAddress>({
    zipcode: user.store?.zipcode || '',
  });
  const [customer, setCustomer] = useState<ICustomer>({
    name: '',
    surname: '',
    email: '',
    phone: '',
    document: '',
    birthdate: '',
  });
  const [totals, setTotals] = useState<IOrderTotals>({
    total_original_price: 0,
    total_current_price: 0,
    shipping_original_price: 0,
    shipping_current_price: 0,
    total: 0,
  });
  const [coupon, setCoupon] = useState<ICoupon>();
  const [orderCode, setOrderCode] = useState<string>('');
  const [items, setItems] = useState<IItem[]>([]);
  const [payments, setPayments] = useState<IPayment[]>([]);
  const [shippingTypes, setShippingTypes] =
    useState<IShippingTypes[]>(initialShippingData);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handlePaymentTotal = (
    paymentList: IPayment[],
    currentTotal: number,
  ) => {
    const onlyOnePaymentMethod = paymentList.length === 1;
    const adjustedPaymentList = paymentList;

    if (onlyOnePaymentMethod && paymentList[0].code === 'store_credit')
      return adjustedPaymentList;

    if (onlyOnePaymentMethod) {
      adjustedPaymentList[0].amount = currentTotal;
      return adjustedPaymentList;
    }

    if ((adjustedPaymentList[0].amount || 0) >= currentTotal) {
      adjustedPaymentList.pop();
      adjustedPaymentList[0].amount = currentTotal;
      return adjustedPaymentList;
    }

    adjustedPaymentList[1].amount =
      currentTotal - (adjustedPaymentList[0].amount || 0);
    return adjustedPaymentList;
  };

  const createBlankOrder = useCallback(
    async customerData => {
      setLoading(true);

      try {
        // API call
        const response = await api.post('/order/simulation', {
          code: '',
          sale_user_code: saleCode.code,
          address,
          customer: {
            name: customerData.name,
            surname: customerData.surname,
            email: customerData.email,
            phone: customerData.phone,
            document: customerData.document,
            birthdate: customerData.birthdate,
          },
          payments: [],
          items: [],
        });

        // Items set
        setItems([]);

        // Order code set
        setOrderCode(response.data.code);

        setLoading(false);
      } catch (err: any) {
        const response = getResponseError(err, t);
        toast.error(response.message);
        setLoading(false);

        throw err;
      }
    },
    [address, saleCode, t],
  );

  const updateSimulation = useCallback(
    async ({
      items: newList = items,
      payments: newPayments = payments,
      orderCode: newOrderCode = orderCode,
      saleCode: newSaleCode = saleCode,
      coupon: newCoupon = coupon,
      address: newAddress = address,
      customer: newCustomer = customer,
    }: ISimulation) => {
      // Check length
      if (!newList.length) {
        setItems([]);
        return;
      }
      setLoading(true);

      const itemShippingId = (item: IItem) => {
        const itemHasNormalId = item.shipping.options.some(
          option => option.id === 'Normal',
        );
        if (item.shipping.selected_id) return item.shipping.selected_id;
        if (itemHasNormalId) return 'Normal';
        return '';
      };

      try {
        const itemsList = newList.map(_item => {
          if (
            _item.reference &&
            giftCardReferences.includes(_item.reference) &&
            _item.attachments
          ) {
            return {
              code: _item.code,
              brand_code: String(_item.brand_code),
              quantity: _item.quantity,
              shipping: {
                selected_type: _item.shipping.selected_type,
                selected_id: itemShippingId(_item),
              },
              attachments: [
                {
                  name: 'GiftCard',
                  content: {
                    GiftCardEmail: _item.attachments.email,
                    GiftCardMessage: 'você recebeu um vale presente',
                    GiftCardName: _item.attachments.name,
                    GiftCardSchedule: _item.attachments.date,
                    GiftCardValue: (_item.unit_original_price / 100).toString(),
                  },
                },
              ],
            };
          }
          return {
            code: _item.code,
            brand_code: String(_item.brand_code),
            quantity: _item.quantity,
            shipping: {
              selected_type: _item.shipping.selected_type,
              selected_id: itemShippingId(_item),
            },
          };
        });

        // API call
        const response = await api.post('/order/simulation', {
          code: newOrderCode,
          sale_user_code: newSaleCode.code,
          ...(newCoupon && {
            coupon: newCoupon.name,
          }),
          address: {
            city: newAddress.city,
            complement: newAddress.complement,
            neighborhood: newAddress.neighborhood,
            number: newAddress.number,
            receiver_name: newAddress.receiver_name,
            state: newAddress.state,
            street: newAddress.street,
            zipcode: newAddress.zipcode,
          },
          customer: newCustomer,
          payments: newPayments.map(_payment => ({
            code: _payment.code,
            payload: {},
          })),
          items: itemsList,
        });

        // Validation
        if (
          !Array.isArray(response.data.items) ||
          typeof response.data.total_current_price !== 'number' ||
          typeof response.data.total_original_price !== 'number' ||
          typeof response.data.shipping_current_price !== 'number' ||
          typeof response.data.shipping_original_price !== 'number' ||
          typeof response.data.total !== 'number'
        )
          throw new Error();

        // Result
        const result = response.data.items.map((item: any) => {
          // Get list item
          const list_item = newList.find(
            new_item =>
              String(new_item.code) === String(item.code) &&
              String(new_item.shipping.selected_type) ===
                String(item.shipping.selected_type),
          );

          // GiftCard condition
          if (
            item.reference &&
            giftCardReferences.includes(item.reference) &&
            item.payload &&
            item.payload.attachments &&
            item.payload.attachments.length
          ) {
            return {
              code: list_item?.code,
              id: item.id,
              available: item.available,
              quantity: item.quantity,
              ean: item.ean,
              name: list_item?.name,
              image_url: item.image_url,
              reference: list_item?.reference,
              color: list_item?.color,
              size: list_item?.size,
              shipping: {
                selected_type: item.shipping.selected_type,
                available: item.shipping.available,
                available_quantity: item.shipping.available_quantity,
                selected_id: item.shipping.selected_id,
                options: item.shipping.options.map((option: IItemShipping) => ({
                  id: option.id,
                  name: option.name,
                  original_price: option.original_price,
                  current_price: option.current_price,
                  business_days_to_delivery: option.business_days_to_delivery,
                  selected: option.selected,
                  type: option.type,
                })),
              },
              attachments: {
                name: item.payload.attachments[0].content.GiftCardName,
                email: item.payload.attachments[0].content.GiftCardEmail,
                date: item.payload.attachments[0].content.GiftCardSchedule,
              },
              unit_original_price: item.unit_original_price,
              unit_current_price: item.unit_current_price,
              total_original_price: item.total_original_price,
              total_current_price: item.total_current_price,
              shipping_original_price: item.shipping_original_price,
              shipping_current_price: item.shipping_current_price,
              brand_code: list_item?.brand_code,
              brand: {
                id: item.brand.id,
                code: item.brand.code,
                name: item.brand.name,
                image_url: item.brand.image_url,
              },
            };
          }

          return {
            code: list_item?.code,
            id: item.id,
            available: item.available,
            quantity: item.quantity,
            ean: item.ean,
            name: list_item?.name,
            image_url: item.image_url,
            reference: list_item?.reference,
            color: list_item?.color,
            size: list_item?.size,
            shipping: {
              selected_type: item.shipping.selected_type,
              available: item.shipping.available,
              available_quantity: item.shipping.available_quantity,
              selected_id: item.shipping.selected_id,
              options: item.shipping.options.map((option: IItemShipping) => ({
                id: option.id,
                name: option.name,
                original_price: option.original_price,
                current_price: option.current_price,
                business_days_to_delivery: option.business_days_to_delivery,
                selected: option.selected,
                type: option.type,
              })),
            },
            unit_original_price: item.unit_original_price,
            unit_current_price: item.unit_current_price,
            total_original_price: item.total_original_price,
            total_current_price: item.total_current_price,
            shipping_original_price: item.shipping_original_price,
            shipping_current_price: item.shipping_current_price,
            brand_code: list_item?.brand_code,
            brand: {
              id: item.brand.id,
              code: item.brand.code,
              name: item.brand.name,
              image_url: item.brand.image_url,
            },
          };
        });

        // Items set
        setItems(result);

        const savedOrderCode = sessionStorage.getItem('savedOrderCode');
        if (!savedOrderCode || savedOrderCode !== response.data.code) {
          sessionStorage.setItem('savedOrderCode', response.data.code);
        }

        // Order code set
        setOrderCode(response.data.code);

        if (newCoupon && newCoupon.name) {
          try {
            const couponResponse = await api.get(
              `/v1/cart/${response.data.code}/discount`,
            );

            if (couponResponse.data.data) {
              const getCouponProductValue = couponResponse.data.data[0].product;
              const getCouponShippingValue =
                couponResponse.data.data[0].shipping;

              setCoupon({
                name: newCoupon.name,
                productDiscount: getCouponProductValue,
                shippingDiscount: getCouponShippingValue,
              });
            }
          } catch (err) {
            toast.error('Desconto indisponível');
          }
        }

        // Check if there is some problem
        if (
          result.some(
            (item: any) =>
              !item.available ||
              (item.shipping.selected_type === 'DELIVERY' &&
                !item.shipping.available &&
                item.shipping.options.length === 0),
          )
        ) {
          toast.error(
            t('SOME_ITEM_IS_UNAVAILABLE', 'Um dos itens está indisponível.'),
          );
        } else if (
          result.some(
            (item: any) =>
              newAddress.street &&
              item.shipping.selected_type === 'DELIVERY' &&
              !item.shipping.available &&
              item.shipping.options.length > 0,
          )
        ) {
          toast.info(
            t(
              'SOME_ITEM_CHANGE_DELIVERY_METHOD',
              'Verifique as opções de entrega',
            ),
          );
        }

        setTotals({
          total_original_price: response.data.total_original_price,
          total_current_price: response.data.total_current_price,
          shipping_original_price: response.data.shipping_original_price,
          shipping_current_price: response.data.shipping_current_price,
          total: response.data.total,
        });

        const paymentTotal = newPayments
          .map(_payment => _payment.amount)
          .reduce((total, current) => (total || 0) + (current || 0), 0);

        if (paymentTotal !== response.data.total && paymentTotal !== 0) {
          const adjustedPayment = handlePaymentTotal(
            newPayments,
            response.data.total,
          );
          setPayments(adjustedPayment);
        }

        setLoading(false);
      } catch (err: any) {
        const response = getResponseError(err, t);
        toast.error(response.message);
        setLoading(false);

        throw err;
      }
    },
    [address, customer, coupon, items, orderCode, payments, saleCode, t],
  );

  const SetUpdateItems = async (newItemsList: IItem[]) => {
    const orderCodeIsAlreadySaved = sessionStorage.getItem('savedOrderCode');
    if (!newItemsList.length && orderCodeIsAlreadySaved) {
      sessionStorage.removeItem('savedOrderCode');
    }
    return updateSimulation({ items: newItemsList });
  };

  // Proceed to order creation
  const createOrder = useCallback(async () => {
    // Clear flags
    setError('');
    setSuccess('');

    if (loading) {
      const message = t('WAIT_TO_LOAD', 'Aguarde um momento.');
      toast.warning(message);
      return;
    }

    // Salecode validation
    if (!saleCode) {
      const message = t('MISSING_SALECODE', 'Informe o código de vendedor.');
      toast.error(message);
      return;
    }

    // Customer validation
    if (
      !customer.name ||
      !customer.surname ||
      !customer.document ||
      !customer.email ||
      !customer.phone
    ) {
      const message = t('MISSING_CUSTOMER', 'Informe o cliente.');
      toast.error(message);
      return;
    }

    // Address validation
    if (
      !address.zipcode ||
      !address.state ||
      !address.city ||
      !address.neighborhood ||
      !address.street ||
      !address.number ||
      !address.receiver_name
    ) {
      const message = t('MISSING_ADDRESS', 'Informe o endereço.');
      toast.error(message);
      return;
    }

    // Item validation
    if (!items.length) {
      const message = t('MISSING_ITEMS', 'A sacola está vazia.');
      toast.error(message);
      return;
    }

    const handleReCaptchaVerify = async () => {
      if (!executeRecaptcha) {
        toast.error(t('CAPTCHA_ERROR', 'Erro na validação reCAPTCHA'));
        return '';
      }

      const token = await executeRecaptcha('submit');

      return token;
    };

    // Remove unnusable items from payment
    delete payments[0].time_limit;

    setLoading(true);
    setCreatingOrder(true);

    // Get captcha token
    const newCaptchaToken = await handleReCaptchaVerify();

    const getAppropriateShippingSelectedId = (item: IItem) => {
      const itemIsOwnStockPackage =
        item.shipping.selected_type === 'OWN_STOCK' &&
        item.shipping.selected_id === 'own_stock';
      if (itemIsOwnStockPackage && onlyDeliveryOptionEnabled(user)) {
        return 'delivery';
      }
      if (itemIsOwnStockPackage && onlyInHandsOptionEnabled(user)) {
        return 'in_hands';
      }
      return item.shipping.selected_id;
    };

    try {
      // API call
      const response = await api.post('/order', {
        origin: 'soma_store',
        ...(coupon?.name ? { coupon: coupon.name } : { coupon: '' }),
        sale_user_code: saleCode.code,
        customer: {
          name: customer.name,
          surname: customer.surname,
          email: customer.email,
          phone: customer.phone,
          document: customer.document,
          birthdate: customer.birthdate,
        },
        address: {
          country: 'BRA',
          code: address.code,
          state: address.state,
          city: address.city,
          neighborhood: address.neighborhood,
          street: address.street,
          number: address.number,
          zipcode: address.zipcode,
          receiver_name: address.receiver_name,
          complement: address.complement,
        },
        total_original_price: totals.total_original_price,
        total_current_price: totals.total_current_price,
        shipping_original_price: totals.shipping_original_price,
        shipping_current_price: totals.shipping_current_price,
        payments: payments.map(_payment => ({
          code: _payment.code,
          payload: {},
          amount: _payment.amount,
        })),
        total: totals.total,
        recaptcha: {
          key: `${process.env.REACT_APP_RECAPTCHA_KEY}`,
          token: newCaptchaToken,
        },
        items: items.map(item => {
          const itemObject = {
            code: item.code,
            brand_code: item.brand_code.toString(),
            quantity: item.quantity,
            shipping: {
              selected_type: item.shipping.selected_type,
              selected_id: getAppropriateShippingSelectedId(item),
            },
            unit_original_price: item.unit_original_price,
            unit_current_price: item.unit_current_price,
            total_original_price: item.total_original_price,
            total_current_price: item.total_current_price,
            shipping_original_price: item.shipping_original_price,
            shipping_current_price: item.shipping_current_price,
          };

          if (
            item.attachments &&
            item.attachments.name &&
            item.attachments.email &&
            item.attachments.date
          ) {
            return {
              ...itemObject,
              attachments: [
                {
                  name: 'GiftCard',
                  content: {
                    GiftCardEmail: item.attachments.email,
                    GiftCardMessage: 'você recebeu um vale presente',
                    GiftCardName: item.attachments.name,
                    GiftCardSchedule: item.attachments.date,
                    GiftCardValue: (item.unit_original_price / 100).toString(),
                  },
                },
              ],
            };
          }

          return {
            ...itemObject,
          };
        }),
      });

      api.delete(`/v1/cart/${orderCode}`);

      if (response?.status === 202) {
        toast.error('Aguarde alguns instantes e tente novamente');
        return;
      }

      // Get the id
      const { id } = response.data;

      // Dispatch data to loading page
      setSuccess(id);

      const orderHasOwnStockItems = !!items.filter(
        item => item.shipping.selected_type === 'OWN_STOCK',
      ).length;
      const hasdocumentAvailabilityWarningBeenSeen = localStorage.getItem(
        'dontShowDocumentAvailabilityWarning',
      );

      // Go to next page
      setTimeout(() => {
        // Go to order page
        const shouldShowModal =
          orderHasOwnStockItems && !hasdocumentAvailabilityWarningBeenSeen
            ? '/showPaymentData/showDocumentWarning'
            : '/showPaymentData';
        history.push(`/order/${id}${shouldShowModal}`);
      }, 4000);
    } catch (err: any) {
      const response = getResponseError(err, t);

      toast.error(response.message);
      setSuccess('');
      setError(response.message);

      // Await to proceed
      await sleep(3000);

      setLoading(false);
      setCreatingOrder(false);
    }
  }, [
    loading,
    saleCode,
    customer.name,
    customer.surname,
    customer.document,
    customer.email,
    customer.phone,
    customer.birthdate,
    address.zipcode,
    address.state,
    address.city,
    address.neighborhood,
    address.street,
    address.number,
    address.receiver_name,
    address.code,
    address.complement,
    items,
    payments,
    t,
    executeRecaptcha,
    user,
    coupon,
    totals.total_original_price,
    totals.total_current_price,
    totals.shipping_original_price,
    totals.shipping_current_price,
    totals.total,
    orderCode,
    history,
  ]);

  const SendUpdateCart = (newPaymentType: IPayment[]) => {
    setPayments(newPaymentType);
    updateSimulation({ payments: newPaymentType });
  };

  const sendUpdateAddress = (newAddress: IAddress) => {
    setAddress(newAddress);
    updateSimulation({ address: newAddress });
  };

  const sendUpdateCustomer = (newCustomer: ICustomer) => {
    setCustomer(newCustomer);
    updateSimulation({ customer: newCustomer });
  };

  const sendUpdateCoupon = async (newCoupon: ICoupon) => {
    setCoupon(newCoupon);
    updateSimulation({ coupon: newCoupon });
  };

  return (
    <OrderCreationContext.Provider
      value={{
        creatingOrder,
        setCreatingOrder,

        error,
        setError,

        success,
        setSuccess,

        addingProduct,
        setAddingProduct,

        editingProduct,
        setEditingProduct,

        saleCode,
        setSaleCode,

        address,
        setAddress: sendUpdateAddress,

        customer,
        setCustomer: sendUpdateCustomer,

        items,
        setItems: SetUpdateItems,
        loading,

        coupon,
        setCoupon: sendUpdateCoupon,

        totals,
        setTotals,

        payments,
        setPayments: SendUpdateCart,

        shippingTypes,
        setShippingTypes,

        orderCode,
        setOrderCode,

        confirmOrder: createOrder,

        createBag: createBlankOrder,
      }}
    >
      {children}
    </OrderCreationContext.Provider>
  );
};

// Hook
export function useOrderCreation(): IOrderCreationContextData {
  // Get data from context
  const context = useContext(OrderCreationContext);

  // If user is not using context provider (DEV purposes only)
  if (!context)
    throw new Error('useAddProduct must be used within a AddProductProvider');

  return context;
}
